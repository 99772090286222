// extracted by mini-css-extract-plugin
export const search = "search-module--search--1sn-i";
export const open = "search-module--open--2PKOj";
export const toggleButton = "search-module--toggleButton--2X-c1";
export const circle = "search-module--circle--3jv6G";
export const line1 = "search-module--line1--1MGlB";
export const line2 = "search-module--line2--3ooOU";
export const searchForm = "search-module--searchForm--27xmi";
export const searchLabel = "search-module--searchLabel--3aNCd";
export const input = "search-module--input--2T7fG";
export const results = "search-module--results--10zvi";
export const empty = "search-module--empty--z8XS-";
export const result = "search-module--result--1d8H8";
export const selectedClass = "search-module--selectedClass--3sh8u";
export const authors = "search-module--authors--PfQhm";
export const type = "search-module--type--3kvSz";
export const missionClass = "search-module--missionClass--31PJi";
export const blogClass = "search-module--blogClass--3frmr";
export const componentClass = "search-module--componentClass--25WDP";
export const ecClass = "search-module--ecClass--1c88P";